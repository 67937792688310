import {Paper, Theme} from "@mui/material"
import {makeStyles} from "@mui/styles"

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary
  }
}))

export interface SIProps {
  children: React.ReactNode
}

export const StackItem = (props: SIProps) => {
  const classes = useStyles()
  return (
    <Paper elevation={0} className={classes.root}>
      {props.children}
    </Paper>
  )
}

export default StackItem
