import "./App.css"
import {CircularProgress} from "@mui/material"
import {ThemeProvider} from "@mui/material/styles"
import {useEffect, useState} from "react"
import {BrowserRouter, Route, Routes} from "react-router-dom"
import AuthChecker from "./components/auth/AuthChecker"
import Center from "./components/utils/Center"
import routes from "./config/routes"
import theme from "./theme"
import {auth} from "./config/firebase"

export interface AppProps {}

export const App: React.FC<AppProps> = (props: AppProps) => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        console.info("User detected.")
      } else {
        console.info("No user detected")
      }
      setLoading(false)
    })
  }, [])

  if (loading)
    return (
      <Center>
        <CircularProgress />
      </Center>
    )

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <Routes>
          {routes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={
                route.protected ? (
                  <AuthChecker>
                    <route.component />
                  </AuthChecker>
                ) : (
                  <route.component />
                )
              }
            />
          ))}
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  )
}

export default App
