import AdapterDateFns from "@mui/lab/AdapterDateFns"
import DatePicker from "@mui/lab/DatePicker"
import LocalizationProvider from "@mui/lab/LocalizationProvider"
import React, {useState} from "react"
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Theme,
  Typography
} from "@mui/material"
import {makeStyles} from "@mui/styles"
import {Navbar} from "../components/Navbar"
import {StackItem} from "../components/StackItem"
import {addQuote} from "../services/quote.service"
import {ITimeSlots} from "../types/quote.type"

export interface ScheduleProps {}

const useStyles = makeStyles((theme: Theme) => ({
  checkbox: {
    color: theme.palette.info.main,
    "&.Mui-checked": {
      color: theme.palette.info.light
    }
  },
  description: {
    marginTop: theme.spacing(5),
    marginLeft: theme.spacing(5)
  },
  form: {
    marginTop: theme.spacing(5),
    marginRight: theme.spacing(5)
  },
  vl: {
    borderLeft: "3px solid",
    borderColor: theme.palette.primary.light,
    height: "88vh",
    marginTop: theme.spacing(3)
  }
}))

export const Schedule: React.FC<ScheduleProps> = (props: ScheduleProps) => {
  const [preferredDate, setPreferredDate] = useState<Date | null>(new Date())
  const [timeSlots, setTimeSlots] = useState<ITimeSlots>({
    morning: false,
    afternoon: false,
    evening: false
  })
  const [details, setDetails] = useState<string>("")
  const [email, setEmail] = useState<string>("")

  const classes = useStyles()

  const createQuote = async () => {
    if (preferredDate) {
      await addQuote({
        preferredDate,
        timeSlots,
        details,
        email
      })
    }
  }

  return (
    <div>
      <Navbar />
      <Grid container xs={12}>
        <Grid item xs={4} className={classes.description}>
          <StackItem>
            <Typography variant="h1" style={{color: "#F78104"}}>
              Fill out the form to request an in-person quote. We will reach out
              to confirm the appointment as soon as possible, thanks!
            </Typography>
          </StackItem>
        </Grid>
        <Grid item xs={1}>
          <div className={classes.vl}></div>
        </Grid>
        <Grid container item xs={4} className={classes.form}>
          <Grid container item xs={6}>
            <Typography variant="h1" style={{color: "#F78104"}}>
              Preferred Date:
            </Typography>
          </Grid>
          <Grid container item xs={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                disablePast
                shouldDisableDate={(day) => day?.getDay() === 0}
                label="Date"
                openTo="day"
                views={["month", "day"]}
                value={preferredDate}
                onChange={(newValue) => {
                  setPreferredDate(newValue)
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid container item xs={6}>
            <Typography variant="h1" style={{color: "#F78104"}}>
              Preferred Times:
            </Typography>
          </Grid>
          <Grid container item xs={6}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    className={classes.checkbox}
                    checked={timeSlots.morning}
                    onChange={() => {
                      setTimeSlots({
                        ...timeSlots,
                        morning: !timeSlots.morning
                      })
                    }}
                  />
                }
                label="Morning (9 a.m. - Noon)"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    className={classes.checkbox}
                    checked={timeSlots.afternoon}
                    onChange={() => {
                      setTimeSlots({
                        ...timeSlots,
                        afternoon: !timeSlots.afternoon
                      })
                    }}
                  />
                }
                label="Afternoon (Noon - 3 p.m.)"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    className={classes.checkbox}
                    checked={timeSlots.evening}
                    onChange={() => {
                      setTimeSlots({
                        ...timeSlots,
                        evening: !timeSlots.evening
                      })
                    }}
                  />
                }
                label="Evening (3 p.m. - 6 p.m.)"
              />
            </FormGroup>
          </Grid>
          <Grid container item xs={6}>
            <Typography variant="h1" style={{color: "#F78104"}}>
              Job Details:
            </Typography>
          </Grid>
          <Grid container item xs={6}>
            <TextField
              id="outlined-multiline-static"
              label="Details"
              multiline
              rows={4}
              style={{width: "100%"}}
              value={details}
              onChange={(e) => {
                setDetails(e.target.value)
              }}
            />
          </Grid>
          <Grid container item xs={6}>
            <Typography variant="h1" style={{color: "#F78104"}}>
              Email Address:
            </Typography>
          </Grid>
          <Grid container item xs={6}>
            <TextField
              id="outlined-basic"
              label="Email"
              variant="outlined"
              style={{width: "100%"}}
              value={email}
              onChange={(e) => {
                setEmail(e.target.value)
              }}
            />
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={6}>
            <Button variant="contained" size="large" onClick={createQuote}>
              Submit Request
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default Schedule
