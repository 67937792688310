import {Dashboard} from "../screens/admin/Dashboard"
import {Contact} from "../screens/Contact"
import {Faq} from "../screens/Faq"
import {Home} from "../screens/Home"
import {Login} from "../screens/Login"
import {Portfolio} from "../screens/Portfolio"
import {Pricing} from "../screens/Pricing"
import {Schedule} from "../screens/Schedule"

interface RouteType {
  path: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: any
  name: string
  protected: boolean
}

const routes: RouteType[] = [
  {
    path: "",
    component: Home,
    name: "Home Screen",
    protected: false
  },
  {
    path: "/login",
    component: Login,
    name: "Login Screen",
    protected: false
  },
  {
    path: "/faq",
    component: Faq,
    name: "Faq Screen",
    protected: false
  },
  {
    path: "/contact",
    component: Contact,
    name: "Contact Screen",
    protected: false
  },
  {
    path: "/portfolio",
    component: Portfolio,
    name: "Portfolio Screen",
    protected: false
  },
  {
    path: "/pricing",
    component: Pricing,
    name: "Pricing Screen",
    protected: false
  },
  {
    path: "/schedule",
    component: Schedule,
    name: "Schedule Screen",
    protected: false
  },
  {
    path: "/admin",
    component: Dashboard,
    name: "Admin Dashboard",
    protected: true
  }
]

export default routes
