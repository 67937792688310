import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  setDoc,
  updateDoc
} from "firebase/firestore"
import {Firestore} from "../config/firebase"
import {IContactData, IUpdateContactData} from "../types/contact.type"

const DB_NAME = "contacts"
const contactsCollection = collection(Firestore, DB_NAME)

// If we have an id
export const setContact = async (contact: IContactData) => {
  if (contact.id) {
    await setDoc(doc(Firestore, DB_NAME, contact.id), contact)
  }
}

// Let Firestore create an id
export const addContact = async (contact: IContactData) => {
  await addDoc(contactsCollection, contact)
  console.log("Contact added")
}

export const getContact = async (id: string) => {
  const snapshot = await getDoc(doc(Firestore, DB_NAME, id))
  if (snapshot.exists()) {
    return snapshot.data()
  } else {
    console.log("Contact doesn't exist")
  }
}

export const getContacts = async () => {
  const snapshot = await getDocs(contactsCollection)
  return snapshot.docs.map((doc) => doc.data())
}

export const updateContact = async (
  contact: IContactData,
  updateData: IUpdateContactData
) => {
  if (contact.id) {
    const contactRef = doc(Firestore, DB_NAME, contact.id)
    await updateDoc(contactRef, {
      ...updateData
    })
  }
}

export const deleteContact = async (contact: IContactData) => {
  if (contact.id) {
    const contactRef = doc(Firestore, DB_NAME, contact.id)
    await deleteDoc(contactRef)
  }
}
