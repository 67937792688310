import {Typography} from "@mui/material"

export interface PricingProps {}

export const Pricing: React.FC<PricingProps> = (props: PricingProps) => {
  return (
    <div>
      <Typography variant="h1">Pricing</Typography>
    </div>
  )
}

export default Pricing
