import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  setDoc,
  updateDoc
} from "firebase/firestore"
import {Firestore} from "../config/firebase"
import {IQuoteData, IUpdateQuoteData} from "../types/quote.type"

const DB_NAME = "quotes"
const quotesCollection = collection(Firestore, DB_NAME)

// If we have an id
export const setQuote = async (quote: IQuoteData) => {
  if (quote.id) {
    await setDoc(doc(Firestore, DB_NAME, quote.id), quote)
  }
}

// Let Firestore create an id
export const addQuote = async (quote: IQuoteData) => {
  await addDoc(quotesCollection, quote)
  console.log("Quote added")
}

export const getQuote = async (id: string) => {
  const snapshot = await getDoc(doc(Firestore, DB_NAME, id))
  if (snapshot.exists()) {
    return snapshot.data()
  } else {
    console.log("Quote doesn't exist")
  }
}

export const getQuotes = async () => {
  const snapshot = await getDocs(quotesCollection)
  return snapshot.docs.map((doc) => doc.data())
}

export const updateQuote = async (
  quote: IQuoteData,
  updateData: IUpdateQuoteData
) => {
  if (quote.id) {
    const quoteRef = doc(Firestore, DB_NAME, quote.id)
    await updateDoc(quoteRef, {
      ...updateData
    })
  }
}

export const deleteQuote = async (quote: IQuoteData) => {
  if (quote.id) {
    const quoteRef = doc(Firestore, DB_NAME, quote.id)
    await deleteDoc(quoteRef)
  }
}
