import React, {useState} from "react"
import {Alert, Button, Grid, TextField, Theme, Typography} from "@mui/material"
import {makeStyles} from "@mui/styles"
import {Navbar} from "../components/Navbar"
import {addContact} from "../services/contact.service"

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    marginTop: theme.spacing(10),
    backgroundColor: theme.palette.background.default
  },
  header: {
    maxHeight: "50px"
  },
  title: {
    color: theme.palette.secondary.main
  },
  input: {
    backgroundColor: "#ffffff90",
    borderRadius: "9px"
  },
  name: {
    marginBottom: theme.spacing(3)
  },
  email: {
    marginBottom: theme.spacing(3)
  },
  message: {
    marginBottom: theme.spacing(3)
  },
  submit: {
    float: "right"
  },
  submitContainer: {},
  formContainer: {
    marginTop: theme.spacing(3)
  },
  alert: {
    margin: theme.spacing(20)
  }
}))

export interface ContactProps {}

export const Contact: React.FC<ContactProps> = (props: ContactProps) => {
  const classes = useStyles()

  const [name, setName] = useState<string>("")
  const [email, setEmail] = useState<string>("")
  const [message, setMessage] = useState<string>("")
  const [showAlert, setShowAlert] = useState<boolean>(false)

  const clearState = () => {
    setName("")
    setEmail("")
    setMessage("")
  }

  const createContact = async () => {
    await addContact({
      name,
      email,
      message
    })

    clearState()
    setShowAlert(true)
  }

  const onNameChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value)
  }

  const onEmailChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value)
  }

  const onMessageChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(event.target.value)
  }

  return (
    <div>
      <Navbar />
      <Grid container xs={12} className={classes.root}>
        <Grid item xs={12} className={classes.header}>
          <Typography variant="h1" className={classes.title}>
            Contact us for more info!
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.formContainer}>
          <TextField
            placeholder="Full Name"
            fullWidth
            autoComplete="none"
            variant="outlined"
            className={classes.name}
            value={name}
            onChange={onNameChanged}
            InputProps={{
              className: classes.input
            }}
          />
          <TextField
            placeholder="Email"
            fullWidth
            autoComplete="none"
            className={classes.email}
            variant="outlined"
            value={email}
            onChange={onEmailChanged}
            InputProps={{
              className: classes.input
            }}
          />
          <TextField
            placeholder="Message"
            fullWidth
            multiline
            rows={5}
            autoComplete="none"
            className={classes.message}
            variant="outlined"
            value={message}
            onChange={onMessageChanged}
            InputProps={{
              className: classes.input
            }}
          />
          <div className={classes.submitContainer}>
            <Button
              type="submit"
              variant="contained"
              className={classes.submit}
              onClick={createContact}
            >
              Submit
            </Button>
          </div>
        </Grid>
      </Grid>
      {showAlert && (
        <Alert variant="filled" className={classes.alert}>
          Your request has been sent! We will get back to you as soon as we can.
        </Alert>
      )}
    </div>
  )
}

export default Contact
