import {Theme, Typography} from "@mui/material"
import {makeStyles} from "@mui/styles"
import {Navbar} from "../components/Navbar"

export interface FaqProps {}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    marginTop: theme.spacing(10),
    backgroundColor: theme.palette.background.default,
    color: theme.palette.secondary.main
  }
}))
export const Faq: React.FC<FaqProps> = (props: FaqProps) => {
  const classes = useStyles()

  return (
    <div>
      <Navbar />
      <div className={classes.root}>
        <Typography variant="h1" style={{color: "#F78104"}}>
          Faq
        </Typography>
      </div>
    </div>
  )
}

export default Faq
