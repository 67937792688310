import "../styles/Home.scss"
import {
  ContactMailOutlined,
  EventNoteOutlined,
  InfoOutlined,
  WorkOutlined
} from "@mui/icons-material"
import {Theme} from "@mui/material"
import {makeStyles} from "@mui/styles"
import {useNavigate} from "react-router-dom"

const Image = require("../resources/images/park-background.jpg")
const Logo = require("../resources/images/logo_light.png")

const useStyles = makeStyles((theme: Theme) => ({
  contactCard: {},
  content: {
    minHeight: "92vh"
  },
  estimateCard: {},
  icon: {
    color: "white"
  },
  infoCards: {},
  logoCard: {
    textAlign: "center"
  },
  menuBar: {
    height: "10vh"
  },
  menuBarButtons: {
    marginTop: "27px"
  },
  separator: {},
  smallLogo: {
    height: "8vh",
    width: "8vh",
    margin: "10px"
  },
  largeLogo: {
    height: "20vh",
    width: "40vh",
    margin: "10px"
  },
  background: {
    background: `linear-gradient(rgba(0, 0, 0, 0.55), rgba(0, 0, 0, 0.55)), url(${Image})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "fixed",
    height: "100vh"
  }
}))

export interface HomeProps {}

export const Home: React.FC<HomeProps> = (props: HomeProps) => {
  const classes = useStyles()
  const navigate = useNavigate()

  return (
    <div className={classes.background}>
      <div className={classes.logoCard}>
        <img src={Logo} alt="Troy's Koiz Logo" className={classes.largeLogo} />
      </div>
      <ul className="honeycomb">
        <li className="honeycomb-cell" onClick={() => navigate("/contact")}>
          <ContactMailOutlined className={classes.icon} sx={{fontSize: 40}} />
          <div className="honeycomb-cell__title">Contact Us</div>
        </li>
        <li className="honeycomb-cell" onClick={() => navigate("/schedule")}>
          <EventNoteOutlined className={classes.icon} sx={{fontSize: 40}} />
          <div className="honeycomb-cell__title">Schedule a Quote</div>
        </li>
        <li className="honeycomb-cell" onClick={() => navigate("/portfolio")}>
          <WorkOutlined className={classes.icon} sx={{fontSize: 40}} />
          <div className="honeycomb-cell__title">Portfolio</div>
        </li>
        <li className="honeycomb-cell" onClick={() => navigate("/faq")}>
          <InfoOutlined className={classes.icon} sx={{fontSize: 40}} />
          <div className="honeycomb-cell__title">FAQ</div>
        </li>
        <li className="honeycomb-cell honeycomb__placeholder"></li>
      </ul>
    </div>
  )
}

export default Home
