import {Navbar} from "../../components/Navbar"

export interface DashboardProps {}

export const Dashboard: React.FC<DashboardProps> = (props: DashboardProps) => {
  return (
    <div>
      <Navbar showLogout />
      <div>
        <p>Dashboard</p>
      </div>
    </div>
  )
}
