import {createTheme} from "@mui/material/styles"

declare module "@mui/material/styles/createPalette" {
  interface Palette {
    neutral: Palette["primary"]
    bright: Palette["primary"]
    grayish: Palette["primary"]
  }
  interface PaletteOptions {
    neutral: PaletteOptions["primary"]
    bright: PaletteOptions["primary"]
    grayish: PaletteOptions["primary"]
  }
}

const fontFamily = "Titillium Web"

const theme = createTheme({
  typography: {
    fontFamily,
    htmlFontSize: 16,
    h1: {
      fontFamily,
      color: "#FFFFFFDD",
      fontSize: 24,
      lineHeight: "36px"
    },
    h2: {
      fontFamily,
      color: "#FFFFFFDD",
      fontSize: 18,
      fontWeight: 700
    },
    h5: {
      color: "#FFFFFFDD",
      fontWeight: "bold"
    },
    h6: {
      fontSize: 24,
      color: "#171725"
    },
    caption: {
      fontFamily,
      color: "#FFFFFFDD",
      fontSize: 12
    }
  },
  palette: {
    primary: {
      main: "#005F60",
      light: "#008083",
      dark: "#052C32"
    },
    secondary: {
      main: "#F78104",
      light: "#FAAB36",
      dark: "#FD5901",
      contrastText: "#052C32"
    },
    error: {
      main: "#FF2200",
      light: "#FF6F4D",
      dark: "#CC0000",
      contrastText: "#FFFFF9"
    },
    warning: {
      main: "#FAAB36",
      light: "#FFDE69",
      dark: "#C77803",
      contrastText: "#052C32"
    },
    info: {
      main: "#1A97EF",
      light: "#4DC0FF",
      dark: "#007DBF"
    },
    success: {
      main: "#008083",
      light: "#4DCDD0",
      dark: "#005F60"
    },
    background: {
      default: "#FFFFF9"
    },
    neutral: {
      main: "#052C32"
    },
    bright: {
      main: "#FFFFF9"
    },
    grayish: {
      main: "#C5D1AD",
      light: "#F8FFE0"
    }
  }
})

export default theme
